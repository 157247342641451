* {
	padding: 0;
	margin: 0;
	list-style: none;
	font-size: 1em;
	box-sizing: border-box;
}

//@mixin dark {
//	@media (prefers-color-scheme: dark) {
//		@content
//	}
//}

@mixin button {
	display: block;
	border-radius: 16px;
	text-indent: 150%;
	overflow: hidden;
	white-space: nowrap;
	width: 32px;
	height: 32px;
	background-repeat: no-repeat;
	background-position: 8px;
	background-size: 16px;
	transition: background-color .1s linear;
	-webkit-backdrop-filter: blur(20px);
	-moz-backdrop-filter: blur(20px);
	backdrop-filter: blur(20px);
	background-color: rgba(200, 200, 200, .25);
	// Disable until Safari supports `prefers-color-scheme` in SVG's
	// @include dark {
	// 	background-color: rgba(0, 0, 0, .25);
	// }
	
	&:hover {
		background-color: rgba(200, 200, 200, .5);
		// Disable until Safari supports `prefers-color-scheme` in SVG's
		// @include dark {
		// 	background-color: rgba(0, 0, 0, .5);
		// }
	}
	
	&:active {
		background-color: rgba(200, 200, 200, .25);
		// Disable until Safari supports `prefers-color-scheme` in SVG's
		// @include dark {
		// 	background-color: rgba(0, 0, 0, .75);
		// }
	}
	
	
}

body,
html {
	min-height: 100%;
}

body {
	font: 16px/24px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	background: #fff;
	color: #2e2f30;

	//@include dark {
	//	background: #000;
	//	color: #eee;
	//}
}

// FOOTER
footer {
	display: none;
	position: relative;
	bottom: 15px;
	height: 60px;
	z-index: 10;
}
.footer-grid {
	display:flex;
	flex-direction: row-reverse;
	justify-content: center;
}

.footer-link {
	justify-self: end;
	align-content: center;
}



// HEADER

header {
	position: sticky;
	top: 0;
	height: 60px;
	z-index: 10;

	
	background: white;
	//@include dark {
	//	background: #000;
	//}
	


	.header-grid {
		display: grid;
		grid-template-columns: 1fr auto;
		align-items: center;
		height: 100%;
	}
	.header-title {
		font-size: 30px;
		font-style: normal;
		font-weight: 500;
		line-height: 42px;
		padding-left: 24px;

	}
	.header-link {
		justify-self: end;
	}
}

header span#breadcrumb {
	margin-left: 16px;
	margin-right: 16px;
}

// MAIN

main {
	display: flex;
}

// DEFAULT PAGE STYLING
.default-layout-wrapper{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 60px);
	width: 100%;
}

.default-layout-wrapper h1 {
	font-size: 30px;
	font-weight: 300;
}

.default-layout-wrapper p {
	font-size: 16px;
	font-weight: 300;
	line-height: 1.5;
}

.default-layout-wrapper .divider {
	width: 100%;
}

.default-layout-wrapper .default-double-container{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
}

.default-layout-wrapper .default-block {
	width: 50%;
	padding: 24px;
	text-align: justify;
}

// PROJECTS GRID/ WORK PAGE

.project-links-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 60px);
	width: 100%;
	-webkit-flex-wrap: wrap;
	-webkit-justify-content: center;
	-webkit-align-items: center;
	overflow: hidden;
}

.project-links{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(1, 1fr);
	gap: 10px; // Adjust the gap between grid items as needed
	padding: 24px; // Adjust the padding as needed
	vertical-align: center;

	.project-link {
		//width: auto;
		height: auto;
		// Additional styling for each project link if needed
	}

	.project-link .link-image {
		width: 100%;
		aspect-ratio: 1/1;
		height: auto;
		object-fit: cover; /* This ensures the image covers the entire area */
	}

	.project-link-container {
		position: relative;
		display: block;
	}

	.project-title {
		position: absolute;
		bottom: 20px; /* Adjust as needed */
		right: -3px; /* Adjust as needed */
		color: white; /* Adjust as needed */
		background-color: rgba(200, 200, 200, 0);
		padding: 0; /* Adjust as needed */
		//border-radius: 16px; /* Adjust as needed */
		//visibility: visible;
		//opacity: 1;
		//transition: visibilty 0s, opacity 0.5s linear;
		font-size: 30px;
		font-weight: 300;
	} :hover {
		.project-title {
			//visibility: hidden;
			//opacity: 0;
			display: none;
		}
	}
}

// PROJECT PAGE
.content-wrapper {
	display: flex;
	flex-direction: row;
	margin-top:0;
	overflow-y: auto;
}

.text-container {
	position: fixed;
	width: 33%;
	height: 100%;
	overflow: auto;
	padding-right: 20px; /* Adjust as needed */
	padding-top: 20px;
	margin-left: 24px;
	line-height: 1.5;
}

.photos-container {
	padding-top: 20px;
	margin-left: 33%;
	width:66%;
	padding-left: 10%; /* Adjust as needed */
	padding-right: 10%; /* Adjust as needed */
}

// PHOTO GRID

.grid {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	align-content: flex-start;
	//gap: 10px;

	&:after {
    content: "";
    display: block;
    flex-grow: 10;
		outline: 2px solid #fff;
		position: relative;

		//@include dark {
		//	outline-color: #000;
		//}
  	}

	.photodetail-links {
		display: none;
	}

	.item {
		//height: 40vh;
		//flex: 1 1 calc(50% - 10px);
		flex-grow: 1;
		outline: 2px solid #fff;
		position: relative;
		background-size: 100%;
		aspect-ratio: 1/1;
		width: 50%;
		overflow: hidden;
		&.mediumFormat{
		}

		&.landscape {
			//aspect-ratio: 16/9;
			aspect-ratio: auto;
			width: 100%;
			display: flex;
		}

		//@include dark {
		//	outline-color: #000;
		//}

		//&:focus,
		//&:hover {
		//	.meta .gridview-button {
		//	   visibility: visible;
		//   }
		//
		//   .open {
		//		background-color: rgba(0, 0, 0, .25);
		//   }
		//}



		img {
			max-height: 100%;
			min-width: 100%;
			min-height: 100%;
			width: auto;
			object-fit: cover;
			display: block;
		}

		.open,
		.close {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			text-indent: 150%;
			overflow: hidden;
			white-space: nowrap;
		}

		.open {
			cursor: zoom-in;
			background-color: rgba(0, 0, 0, 0);
			transition: background-color .15s ease-out;

			//&:hover,
			//&:focus {
			//	background-color: rgba(0, 0, 0, .25);
			//}

			&:active {
				background-color: rgba(0, 0, 0, .5);
			}
		}

		.close {
			display: none;
			cursor: zoom-out;
		}

		.full {
			display: none;
		}

		.previous,
		.next {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: 20;
			width: 80px;
			display: none;
			align-items: center;
			justify-content: center;
			
			span {
				@include button;
				background-image: url(../img/icon-left.svg);
				pointer-events: none;
			}
			
			&:hover,
			&:focus {
				span {
					background-color: rgba(200, 200, 200, .5);
					// Disable until Safari supports `prefers-color-scheme` in SVG's
					// @include dark {
					// 	background-color: rgba(0, 0, 0, .5);
					// }
				}
			}

			&:active {
				span {
					background-color: rgba(200, 200, 200, .25);
					// Disable until Safari supports `prefers-color-scheme` in SVG's
					// @include dark {
					// 	background-color: rgba(0, 0, 0, .75);
					// }
				}
			}
		}

		.next {
			right: 0;
			left: auto;
			
			span {
				background-image: url(../img/icon-right.svg);
			}
		}

		.meta {
			display: flex;
			position: absolute;
			right: 4px;
			bottom: 12px;
			color: #fff;
			text-shadow: #000 0 1px 1px, #000 0 2px 4px;
			opacity: .5;
		
			li {
				margin-right: 12px;

				.download  {
					background-image: url(../img/icon-download.svg);
				}

				.share  {
					background-image: url(../img/icon-share.svg);
					cursor: pointer;
				}

				.gridview-button {
					@include button;
					@media not all and (hover: none) {
						visibility: hidden;
					}
					background-color: rgba(200, 200, 200, .5);

					&:focus,
					&:hover {
						background-color: rgba(200, 200, 200, .75);
					}

					&:active {
						background-color: rgba(200, 200, 200, 1);
					}
				}
			}
		}
		
		// PHOTO DETAIL

		&.target {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			height: 100%;
			z-index: 10;
			background: #fff;
			display: flex;
			align-items: center;
			width: 100%;

			@media (prefers-color-scheme: dark) {
				background: #000;
			}

			.photodetail-links {
				display: flex;
				z-index: 21;
			}

			.open {
				display: none;
			}

			.close {
				display: block;
			}

			img {
				object-fit: contain;
				animation: fade-in .5s ease-out;
			}

			.full {
				display: flex;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				animation: fade-in .5s ease-out;
				
				span {
					flex-grow: 1;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
				}
			}

			.meta {
				display: none !important;
			}

			.photoview-button {
				height: 75px;
				
				span {

					&:focus,
					&:hover {
						background-color: rgba(200, 200, 200, .5);
					}

					&:active {
						background-color: rgba(200, 200, 200, .75);
					}
				}
			}

			.previous,
			.next,
			.download {
				display: flex;
			}
		}
	}
}

// SOCIAL LINKS

.links {
	position: fixed;
	right: 24px;
	display: flex;
	flex-wrap: wrap;
	margin-left: 16px;

	&.bottom {
			position: relative;
		}

	&.top {
	
		top: 14px;
		
	}
	
	li {
		margin-left: 8px;
		
		a {
			@include button;
		}
		
		&.github {
			a {
				background-image: url(../img/icon-github.svg);
			}
		}

		&.twitter {
			a {
				background-image: url(../img/icon-twitter.svg);
			}
		}

		&.instagram {
			a {
				background-image: url(../img/icon-instagram.svg);
			}
		}
		
		&.rss {
			a {
				background-image: url(../img/icon-rss.svg);
			}
		}

		&.sort {
			a {
				background-image: url(../img/icon-sort.svg);
			}
		}

		&.share {
			a {
				cursor: pointer;
				background-image: url(../img/icon-share.svg);
			}
		}

		&.download {
			a {
				background-image: url(../img/icon-download.svg);
			}
		}
		
		&.link {
			a {
				text-indent: 0;
				width: auto;
				font-size: 13px;
				line-height: 32px;
				text-transform: uppercase;
				padding: 0 12px;
				color: rgba(0, 0, 0, .75);
				font-weight: 600;
				text-decoration: none;
			}
		}
	}
}

// 404

.four-oh-four {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 64px;
	align-items: center;
	justify-content: center;
	text-align: center;
	
	img {
		width: 64px;
		vertical-align: bottom;
		margin-bottom: 24px;
	}
	
	h1 {
		font-size: 32px;
		line-height: 48px;
		font-weight: 700;
	}
	
	p {
		margin-bottom: 32px;
	}
	
	a {
		@include button;
		text-indent: 0;
		width: auto;
		font-size: 13px;
		line-height: 32px;
		text-transform: uppercase;
		padding: 0 12px;
		color: rgba(0, 0, 0, .75);
		font-weight: 600;
		text-decoration: none;
	}
}

// RESPONSIVE

.no-scroll {
	overflow: hidden;
}

//@media (max-aspect-ratio: 1/1) {
//	.grid {
//		.item {
//			height: 30vh;
//		}
//	}
//
//
//}

//small
@media (max-width: 576px) {
	.grid {
		.item {
			width: 100%;
		}
	}

	.project-links {
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(3, 1fr);
		overflow: auto;
	}
	
	.project-links-container {
		height: auto;
	}


	.content-wrapper {
		flex-direction: column;
	}
	.text-container {
		display: inline-block;
		width: auto;
		position: relative;
	}
	.photos-container {
		padding-left: 15px;
		padding-right: 15px;
		width: 100%;
		margin-left: 0;
	}

	.header {
		position: relative;
	}
	
}

// smallish
@media (min-width: 576px) and (max-width: 768px) {
	.project-links {
		grid-template-columns: repeat(2, 1fr);
	}

}

// medium
@media (max-width: 1160px) {
	.grid {
		.item {
			width: 100%;
		}
	}
	.default-layout-wrapper .default-block {
		width: 100%;
	}

	.default-layout-wrapper {
		justify-content: start;
		padding: 24px;
		height: auto;
	}

	.default-layout-wrapper .default-double-container {
		flex-direction: column;
		align-items: center;
	}
}

@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
	.grid {
		flex-direction: row;

		.item {
			height: auto;
			width: 100%;

			img {
				width: 100%;
				height: auto;
			}
			
			.previous,
			.next {
				width: 25vw;
				max-width: auto;
				
				span {
					display: none;
				}
			}
			
			.previous {
				cursor: w-resize;
			}
			
			.next {
				cursor: e-resize;
			}

			.meta {
				li {
					.gridview-button {
						visibility: visible;
					}
				}
			}
		}
	}

	.header-link {
		display: none;
	}
	
	footer {
		display: block;
	}
	
	.project-links-container {

	}
}

// ANIMATIONS

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}